.Summons {
  margin: 0 auto;
  overflow: hidden;
}

.table-container {
  margin: 5rem 2rem;
}

.summons-heading {
  width: 100%;
  margin-bottom: 1rem;
}

.summons-heading > div {
  margin: 0 auto;
  width: fit-content;
  display: flex;
}

.summons-heading > div > svg {
  cursor: pointer;
}

.save-newitem-button {
  cursor: pointer;
  position: absolute;
  right: 3rem;
  top: 9rem;
  background: #FFF;
  border: none;
  padding: 0;
}

.save-newitem-button:hover {
  background: #FFF;
  border: none;
}

.save-newitem-button:disabled {
  background: #FFF;
  border: none;
}

.save-newitem-button:focus {
  background: #FFF;
  border: none;
  box-shadow: none;
}

.save-newitem-button-icon {
  cursor: pointer;
  color: #1866b4;
  font-size: 2.5rem;
  right: 3rem;
  top: 9rem;
}

.undo-new-button {
  cursor: pointer;
  color: #cc4300;
  font-size: 2rem;
  position: absolute;
  right: 8rem;
  top: 9.3rem;
}

.summons-table {
  height: calc(100vh - 15rem);
  overflow-y: auto;
  display: block;
  border: none;
}

.summons-table > tbody, .summons-table > thead {
  border-left: 2px solid #343a40;
  border-right: 2px solid #343a40;
}

.summons-table > thead {
  border-left: 2px solid #343a40;
  border-right: 2px solid #343a40;
  border-top: 0px solid #343a40;
}

.summons-table > thead > tr > th {
  width: 11rem;
  position: sticky;
  top: 0px;
  background: #343a40;
  z-index: 2;
  height: 2.5rem;
  vertical-align: middle;
  color: #ddd;
  border-style: none solid;
}

.summons-table > tbody > tr > td {
  width: 11rem;
  vertical-align: middle;
}

.barcode-button-new {
  cursor: pointer;
  color: #343a40;
  background: #FFF;
  border: none;
  box-shadow: none !important;
  padding: 0px;
}

/* OVERWRITES */

.react-datepicker-wrapper > div > input {
  max-width: 9rem;
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 0px !important;
}
