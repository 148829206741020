.Item {
  margin: 0 auto;

    overflow: auto;
    height: 100vh;
}

.item-container {
  margin: 5rem 2rem;
}

.item-heading {
  width: 100%;
  margin-bottom: 1rem;
}

.item-heading > div {
  margin: 0 auto;
  width: 17rem;
  display: flex;
}

.modal-save-pdf > div {
  max-width: 40vw !important;
}

.upload-pdf-button {
  cursor: pointer;
  position: absolute;
  left: 2rem;
  top: 9rem;
  font-size: 1.2rem;
}

.pdf-selector-container {
  display: flex;
}

.pdf-selector-container > input {
  width: 100%;
}

.pdf-selector-container svg {
  font-size: 2rem;
  margin-left: 2rem;
  cursor: pointer;
}

.save-pdf-loading {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  z-index: 99;
}

.undo-item-button {
  cursor: pointer;
  color: #cc4300;
  font-size: 2rem;
  position: absolute;
  right: 21rem;
  top: 9.2rem;
}

.save-item-button {
  cursor: pointer;
  color: #1866b4;
  font-size: 2.5rem;
  position: absolute;
  right: 16rem;
  top: 8.9rem;
}

.report-button {
  cursor: pointer;
  color: #343a40;
  font-size: 2.5rem;
  position: absolute;
  right: 8rem;
  top: 9rem;
}

.barcode-button {
  cursor: pointer;
  color: #343a40;
  position: fixed;
  top: 15rem;
  right: 2.5rem;
  background: #FFF;
  border: none;
  box-shadow: none !important;
  padding: 0px;
}

.delete-button {
  cursor: pointer;
  position: absolute;
  right: 3rem;
  top: 9rem;
  font-size: 1.2rem;
}

.delete-modal {
  background: #FFF;
  width: 40vw;
}

.item-details-table {
  width: 50%;
  position: sticky;
  background: #fff;
}

.item-details-table tbody tr td {
  border: none;
  width: 23%;
}

.item-details-table tbody tr td:nth-child(even) {
  border: none;
  width: 25%;
}


.item-table {
  border: none;
  border-radius: 4px;
}

.item-table > tbody, .item-table > thead {
  border-left: 2px solid #343a40;
  border-right: 2px solid #343a40;
  border-bottom: 2px solid #343a40;
}

.item-table > thead {
  border-left: 2px solid #343a40;
  border-right: 2px solid #343a40;
  border-top: 0px solid #343a40;
}

.item-table > thead > tr > th {
  background: #343a40;
  z-index: 2;
  height: 2.5rem;
  vertical-align: middle;
  color: #ddd;
  border-style: none solid;
}

.item-table > tbody > tr > td {
  vertical-align: middle;
}

.summons-item-table > thead > tr > th {
  width: 8%;
}

.summons-item-table > thead > tr > th:nth-child(1) {
  max-width: 8rem;
}

.sheriff-item-table > thead > tr > th {
  width: 15%;
  min-width: 8rem;
}

.sheriff-item-table > thead > tr > th:nth-child(1) {
  width: 10rem;
}

.judge-item-table > thead > tr > th {
  width: 8%;
}

.judge-item-table > thead > tr > th:nth-child(1) {
  max-width: 8rem;
}

.judge-item-table > thead > tr > th:nth-child(2) {
  min-width: 10rem;
}

.notes-accordion > div {
  border: 2px solid #343a40;
}

.notes-toggle-button:focus {
  box-shadow: 0 0 0 0.1rem #17a2b8;
}

.item-notes-table {
  width: 100%;
}

.item-notes-table tbody tr td {
  border: none;
}

.item-tabs {
  background: #FFF !important;
  border-bottom: 2px solid #343a40;
}

.item-tabs a {
  width: 12rem;
  color: #333 !important;
  text-align: center;
}

.item-tabs a.active {
  color: #FFF !important;
  background: #367dc4 !important;
  border: none !important;
}

.item-tabs a:hover {
  color: #FFF !important;
  background: #367dc4 !important;
}

.add-note-icon {
  font-size: 1.3rem;
}

.custom-select:disabled {
  color: #212529;
  background-color: #FFF !important;
  background: #FFF !important;
}

select[disabled] > option {
  color: #212529 !important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #fff;
    opacity: 1;
    color: #212529;
}

.pdf-accordion > div {
  border: 2px solid #343a40;
}

.pdf-accordion > div > div {
  display: flex;
}

.pdf-accordion > div > div > button {
  width: 12rem;
}

.pdf-listgroup {
  width: 50%;
  height: 24rem;
  overflow: auto;
}

.pdf-listgroup > div {
    width: 99%;
    text-align: center;
    padding-top: 1rem;
    height: 5.5rem;
    border-radius: 5px;
    margin: 0.2rem;
    border: 1px solid #ddd !important;
}

.pdf-item-icon {
  font-size: 1.5rem;
  color: #b90c0d;
  margin-bottom: 0.5rem;
}

.item-pdfList-form {
  margin: 0.5rem;
  height: 4rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
}

.item-pdfList-form > div {
  width: 100%;
}

.item-pdfList-form > svg {
  font-size: 2rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
  color: #b90c0d;
  margin-bottom: 0.5rem;
}

.item-pdfList-form > div > input {
  width: 1.3rem;
  height: 1.5rem;
}

.item-pdfList-form > div > label {
  display: flex;
  margin-left: 1rem;
}

.item-pdfList-form > div > label > svg {
  font-size: 2rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
  color: #b90c0d;
  margin-bottom: 0.5rem;
}

.item-pdf-download {
  cursor: pointer;
}

.item-pdfList-form > div > label > p {
  font-size: 1.3rem;
  margin-right: 1rem;
}
