.Management {
  margin: 0 auto;
  overflow: hidden;
}

.man-container {
  margin: 5rem 2rem;
}

.management-tabs a {
  width: 12rem;
}

.man-view > div {
  margin-bottom: 1rem;
}

.man-view > div:hover {
  background: #367dc4;
  border-radius: 4px;
}

.man-view > div:hover > div > div > a > a {
  color: #333 !important;
  background: #FFF;
  background-color: #FFF;
}

.man-view > div:hover > div > div > a > a:active {
  color: #333 !important;
  background: #FFF;
  background-color: #FFF;
}

.man-view > div:hover a {
  color: #FFF;
}

.man-view > div > a.active {
  background-color: #367dc4 !important;
}

.man-columns-nav > div {
  height: 100%;
}

.man-columns-nav > div > button {
  background: #FFF;
  border: none;
  color: #333;
  height: 100%;
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
}

.man-columns-nav > div > div {
  background: #FFF;
  color: #333;
  text-align: left;
  width: 100%;
}

.man-columns-nav > div > div:hover {
  background: #FFF;
  color: #333;
  text-align: left;
  width: 100%;
}

.man-columns-nav > div > div > a > a {
  background: #FFF;
  color: #333 !important;
  text-align: left;
  width: 100%;
  padding: 0;
}

.man-columns-nav > div > div > a > a:active {
  background: #FFF;
  background-color: #FFF !important;
  color: #333 !important;
  text-align: left;
  width: 100%;
  padding: 0;
}

.man-columns-nav > div > div > a:hover > a {
  background: #e9ecef;
  background-color: #e9ecef !important;
  color: #333 !important;
  text-align: left;
  width: 100%;
  padding: 0;
}

.nav-pills .nav-link.active {
  background: #FFF;
  background-color: #FFF;
  color: #FFF;
}

.man-columns-nav > div > button:hover {
  background: #367dc4;
  border-radius: 4px;
  color: #FFF;
}

.man-columns-nav > div > button:hover {
  background: #367dc4;
  border-radius: 4px;
  color: #FFF;
}

.man-columns-nav > div > button:focus {
  background: #367dc4;
  border-radius: 4px;
  color: #FFF !important;
  box-shadow: none !important;
  background-color: #367dc4 !important;
}

.man-columns-nav > div > button::after {
  margin-left: 1rem;
}

.man-roles-drop {
  width: 20rem;
  color: #333;
  background: #FFF;
  border: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.save-man-button {
  cursor: pointer;
  color: #1866b4;
  font-size: 2.5rem;
}

.undo-man-button {
  cursor: pointer;
  font-size: 2rem;
  color: #cc4300;
}

.add-man-button {
  cursor: pointer;
  color: #19A979;
  font-size: 2.5rem;
}

.man-scroll-table {
  height: calc(100vh - 20rem);
  overflow-y: auto;
  display: block;
  border: none !important;
}
