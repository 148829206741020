.QuickView {
  margin: 0 auto;
  overflow: hidden;
  background: transparrent;
  z-index: 9
}

.background-home-img-quick {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 35%;
  margin-left: -17.5%;
  opacity: 0.7;
  z-index: 1;
}

.table-container {
  margin: 5rem 2rem;
}

.heading-quick-view {
  width: 100%;
  margin: 5rem 2rem;
  display: flex;
}

.heading-quick-view > span {
  margin: 5px 1rem 0 0;
  font-size: 1.2rem;
}

.quick-search-loading {
  margin-top: 8px;
  position: absolute;
  right: 26rem;
  width: 20rem;
  height: 2.5rem;
}

.quick-zipping-text {
  margin-top: 10px;
  position: absolute;
  right: 51rem;
}

.quick-zipping {
  margin-top: 8px;
  position: absolute;
  right: 48rem;
}

.download-pdf-button {
  position: absolute;
  height: 3rem;
  border: 1.6px solid #b90c0d;
  padding: 0.5rem;
  right: 2rem;
  border-radius: 5px;
  cursor: pointer;
  width: 22rem;
}

.download-pdf-button:hover {
  background: #b90c0d;
}

.download-pdf-button > p {
  font-size: 1.2rem;
}

.download-pdf-button:hover > p {
  color: #FFF;
}

.download-pdf-button:hover > svg {
  color: #FFF;
}

.download-pdf-button > svg {
  font-size: 1.7rem;
  color: #b90c0d;
  margin-right: 1rem;
}

.quick-pdfList-form {
  margin: 0.5rem;
  height: 4rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.quick-pdfList-form > div > input {
  width: 1.3rem;
  height: 1.5rem;
}

.quick-pdfList-form > div > label {
  display: flex;
  margin-left: 1rem;
}

.quick-pdfList-form > div > label > svg {
  font-size: 2rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
  color: #b90c0d;
  margin-bottom: 0.5rem;
}

.pdf-item-download {
  position: absolute;
  right: 1rem;
  cursor: pointer;
}

.quick-pdfList-form > div > label > p {
  font-size: 1.3rem;
  margin-right: 1rem;
}

.react-datepicker-popper {
  z-index: 99 !important;
}

.quickview-pdf-button {
  cursor: pointer;
  color: #b90c0d;
  font-size: 3rem;
  margin-right: 1rem;
}

.download-pdf-loading {

}

.quick-pdf-listgroup > div {
  display: flex;
  padding-top: 1.2rem;
  padding-bottom: 0;
}

.quick-pdf-listgroup > div > svg {
  margin-right: 1rem;
}

.pdf-item-icon-quick {
    font-size: 1.5rem;
    color: #b90c0d;
    margin-bottom: 0.5rem;
}

.quickview-table {
  height: calc(100vh - 15rem);
  overflow-y: auto;
  display: block;
  border: none;
  width: 100%;
}

.quickview-table > tbody, .quickview-table > thead {
  border-left: 2px solid #343a40;
  border-right: 2px solid #343a40;
}

.quickview-table > thead {
  border-left: 2px solid #343a40;
  border-right: 2px solid #343a40;
  border-top: 0px solid #343a40;
}

.quickview-table > thead > tr > th {
  position: sticky;
  top: 0px;
  background: #343a40;
  z-index: 2;
  height: 2.5rem;
  vertical-align: middle;
  color: #ddd;
  border-style: none solid;
}

.quickview-table > tbody > tr {
    cursor: pointer;
}

.quick-summons > thead > tr > th {
  width: 8%;
}

.quick-summons > thead > tr > th:nth-child(1) {
  max-width: 8rem;
}

.quick-summons > thead > tr > th:nth-child(2) {
  min-width: 10rem;
}

.quick-sheriff > thead > tr > th {
  width: 15%;
  min-width: 8rem;
}

.quick-sheriff > thead > tr > th:nth-child(1) {
  width: 10rem;
}

.quick-judge > thead > tr > th {
  width: 8%;
}

.quick-judge > thead > tr > th:nth-child(1) {
  max-width: 8rem;
}

.quick-judge > thead > tr > th:nth-child(2) {
  min-width: 10rem;
}
