
.nav-tabs {
  background: #6C8893;

  border-bottom: 2px solid #343a40;
}

.nav-tabs > div {
  width: 15rem;
  border-right: 1px solid #343a40;
  border-left: 1px solid #343a40;
  border-radius: 0;
  background: #6C8893;
  height: 3rem;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border: none;
    background: #2F6497;
    color: #FFF;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    color: #FFF;
    height: 3rem;
    font-size: 18px;
    display: flex;
}

.nav-tabs .nav-link > span {
    margin-top: 2px;
    margin-left: 1rem;
}

.nav-tabs .nav-link > svg {
    margin-top: 3px;
    font-size: 1.5rem;
}

.nav-tabs .dropdown-menu {
  height: fit-content;
  border-left: 1px solid #343a40;
  border-right: 1px solid #343a40;
  border-bottom: 1px solid #343a40;
  padding: 0;
  background: #FFF;
  width: 100%;
}

.nav-tabs .dropdown-menu > a {
  height: 2.5rem;
  font-size: 16px;
  color: #333;
  padding-top: 0.5rem;
}

.nav-tabs .dropdown-menu > a:hover {
  background: #2F6497;
  color: #FFF;
}

.navbar-dropdowns::after {
  margin: 0.8rem 0 0 1.5rem;
}

.background-home-img {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 35%;
  margin-left: -17.5%;
  opacity: 0.7;
}
