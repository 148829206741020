.Summons {
  margin: 0 auto;
  overflow: hidden;
}

.table-container {
  margin: 5rem 2rem;
}

.summons-update-heading {
  width: 100%;
  margin-bottom: 1rem;
}

.summons-update-heading > div {
  margin: 0 auto;
  width: 20rem;
  display: flex;
}

.summons-update-heading > div > svg {
  cursor: pointer;
}

.judgement-update-heading {
  width: 100%;
  margin-bottom: 1rem;
}

.judgement-update-heading > div {
  margin: 0 auto;
  width: 21rem;
  display: flex;
}

.judgement-update-heading > div > svg {
  cursor: pointer;
}

.sheriff-update-heading {
  width: 100%;
  margin-bottom: 1rem;
}

.sheriff-update-heading > div {
  margin: 0 auto;
  width: 25rem;
  display: flex;
}

.sheriff-update-heading > div > svg {
  cursor: pointer;
}

.other-heading {
  width: 100%;
  margin-bottom: 1rem;
}

.other-heading > div {
  margin: 0 auto;
  width: 16rem;
  display: flex;
}

.other-heading > div > svg {
  cursor: pointer;
}

.save-update-button {
  cursor: pointer;
  color: #1866b4;
  font-size: 2.5rem;
  position: absolute;
  right: 3rem;
  top: 9rem;
}

.undo-update-button {
  cursor: pointer;
  color: #cc4300;
  font-size: 2rem;
  position: absolute;
  right: 8rem;
  top: 9.3rem;
}

.update-table {
  height: calc(100vh - 15rem);
  overflow-y: auto;
  display: block;
  border: none;
}

.update-table > tbody, .update-table > thead {
  border-left: 2px solid #343a40;
  border-right: 2px solid #343a40;
}

.update-table > thead {
  border-left: 2px solid #343a40;
  border-right: 2px solid #343a40;
  border-top: 0px solid #343a40;
}

.update-table > thead > tr > th {
  position: sticky;
  top: 0px;
  background: #343a40;
  z-index: 2;
  height: 2.5rem;
  vertical-align: middle;
  color: #ddd;
  border-style: none solid;
}

.summons-update-table > thead > tr > th {
  width: 5rem;
  /* font-size: 12px; */
}

.sheriff-update-table > thead > tr > th {
  width: 15%;
  min-width: 8rem;
}

.judge-update-table > thead > tr > th {
  width: 8%;
}

.other-update-table > thead > tr > th {
  width: 15%;
  min-width: 8rem;
}

.update-table > tbody > tr > td {
  width: 11rem;
  vertical-align: middle;
}

/* OVERWRITES */

.react-datepicker-wrapper > div > input {
  max-width: 9rem;
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 0px !important;
}
