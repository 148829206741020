.Summons {
  margin: 0 auto;
  overflow: hidden;
}

.summons-heading-report {
  width: 100%;
  margin-bottom: 1rem;
}

.summons-heading-report > div:nth-child(1) {
  width: 36rem;
  margin: 0 auto;
}

.summons-heading-report > div:nth-child(1) > select:nth-child(1){
  width: 10rem;
}

.summons-heading-report > div:nth-child(2) {
  width: 17rem;
  margin: 0 auto;
}

.report-select-client {
  position: absolute;
  left: 2rem;
  display: flex;
}

.report-select-client > span {
  width: 16rem;
  padding-top: 6px;
  font-size: 1.1rem;
  font-weight: bold;
}

.sheriff-heading-report {
  width: 100%;
  margin-bottom: 1rem;
}

.sheriff-heading-report > div:nth-child(1) {
  width: 36rem;
  margin: 0 auto;
}

.sheriff-heading-report > div:nth-child(2) {
  width: 22rem;
  margin: 0 auto;
}

.judge-heading-report {
  width: 100%;
  margin-bottom: 1rem;
}

.judge-heading-report > div:nth-child(1) {
  width: 36rem;
  margin: 0 auto;
}

.judge-heading-report > div:nth-child(2) {
  width: 18rem;
  margin: 0 auto;
}

.other-heading-report {
  width: 100%;
  margin-bottom: 1rem;
}

.other-heading-report > div:nth-child(1) {
  width: 36rem;
  margin: 0 auto;
}

.other-heading-report > div:nth-child(2) {
  width: 17rem;
  margin: 0 auto;
}

.report-undo-button {
  cursor: pointer;
  color: #343a40;
  font-size: 2.2rem;
  position: absolute;
  right: 13rem;
  top: 9.2rem;
}

.outstanding-button {
  cursor: pointer;
  position: absolute;
  right: 15rem;
  top: 9rem;
}

.print-excel-button {
  cursor: pointer;
  color: #0e8c62;
  font-size: 2.5rem;
  position: absolute;
  right: 8rem;
  top: 9rem;
}

.print-pdf-button {
  cursor: pointer;
  color: #b90c0d;
  font-size: 2.5rem;
  position: absolute;
  right: 3rem;
  top: 9rem;
}

.report-table {
  height: calc(100vh - 15rem);
  overflow-y: auto;
  display: block;
  border: none;
  width: 100%;
}

.report-table > tbody, .report-table > thead {
  border-left: 2px solid #343a40;
  border-right: 2px solid #343a40;
}

.report-table > thead {
  border-left: 2px solid #343a40;
  border-right: 2px solid #343a40;
  border-top: 0px solid #343a40;
}

.report-table > thead > tr > th {
  position: sticky;
  top: 0px;
  background: #343a40;
  z-index: 2;
  height: 2.5rem;
  vertical-align: middle;
  color: #ddd;
  border-style: none solid;
}

.summons-report-table > thead > tr > th {
  width: 8%;
}

.summons-report-table > thead > tr > th:nth-child(1) {
  max-width: 8rem;
}

.sheriff-report-table > thead > tr > th {
  width: 15%;
  min-width: 8rem;
}

.sheriff-report-table > thead > tr > th:nth-child(1) {
  width: 10rem;
}

.judge-report-table > thead > tr > th {
  width: 8%;
}

.judge-report-table > thead > tr > th:nth-child(1) {
  max-width: 8rem;
}

.judge-report-table > thead > tr > th:nth-child(2) {
  min-width: 10rem;
}

.report-filter-dropdown {
  width: 100%;
}

.report-filter-dropdown button {
  color: #FFF;
  font-weight: bold;
  text-align: left;
}

.report-filter-dropdown button::after {
  display: none !important;
}

.report-filter-dropdown button:focus {
  box-shadow: none !important;
}

.report-filter-dropdown button:hover {
  color: #FFF;
}

.report-filter-dropdown-menu {
  left: -5px !important;
  top: 3px !important;
  width: 14rem;
}

.report-table > tbody > tr > td {
  width: 11rem;
  vertical-align: middle;
}
