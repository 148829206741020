.App {
  margin-top: 4.1rem;
  overflow: hidden;
}

.Navbar {

}

.header img {
  float: left;
  width: 100px;
  height: 100px;
  background: #555;
}

.search-input {
  width: 25rem !important;
  border-radius: 0 5px 5px 0;
}

.nav-icons {
  font-size: 1.5rem;
}

.nav-color {
  cursor: pointer;
  color: #DDD;
}

.nav-tabs {
  position: fixed;
  width: 100%;
  margin: 0 0 0 0;
  z-index: 99;
}

a {
  color: #333;
}

.search-dropdown {
  position: absolute;
  top: 2.5rem;
  left: 4.1rem;
  width: 25rem;
  max-height: 50vh;
  overflow-y: auto;
}

.list-group-item {
  cursor: pointer;
}

.list-group-item:hover {
  background: #367dc4;
  color: #FFF;
}

.btn-outline-info:hover {
  background: #367dc4;
  border-color: #367dc4;
}

td select {
  font-size: 14px !important;
}

td input {
  font-size: 14px !important;
}

td {
  font-size: 14px !important;
}

.loading-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9999;
}

.loading-spinner > img {
  position: absolute;
  width: 20%;
  height: 25%;
  bottom: 50%;
  left: 40%;
}

.loading-spinner > div {
  position: absolute;
  bottom: 45%;
  left: 49%;
}

.search-loading {
  position: absolute;
  right: 3.5rem;
  top: 0.1rem;
  z-index: 99;
}

.search-icon {
  position: absolute;
  right: 3.5rem;
  top: 0.1rem;
  z-index: 99;
  font-size: 2rem;
  cursor: pointer;
}

.item-not-found-search {
  position: absolute;
  right: 3.5rem;
  top: 0.5rem;
  z-index: 99;
}
