.Login {
  padding: 5rem 0;
}

.Login form {
  margin: 0 auto;
  max-width: 320px;
}

.Login form div {
  text-align: center;
  display: grid;
}

.Login form button {
  text-align: center;
  display: grid;
  margin: 2rem auto;
  width: 10rem;
}
